<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <i class="el-icon-lx-cascades"></i> 續約到期列表
                </el-breadcrumb-item>
            </el-breadcrumb>

        </div>
        <div class="container">
            <el-tabs v-model="activeName"
                     type="card">
                <el-tab-pane label="當月資料"
                             name="current">
                    <Current></Current>
                </el-tab-pane>
                <el-tab-pane label="歷史資料"
                             name="history">
                    <History></History>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
import Current from './components/Current.vue'
import History from './components/History.vue'
export default {
    components: {
        Current,
        History,
    },
    data() {
        return {
            activeName: 'current',
        }
    },
}
</script>

<style lang="scss" scoped>
</style>