<template>
    <div>
        <el-form :inline="true"
                 :model="formInline"
                 class="demo-form-inline">
            <el-form-item label="月份:">
                {{thisMonth}}
            </el-form-item>
            <el-form-item label="客戶ID:">
                <el-input v-model="formInline.merchantId"
                          placeholder="請以,區隔(如:18,24,11)"></el-input>
            </el-form-item>
            <el-form-item label="網域來源:"
                          prop="source_id">
                <el-select v-model="formInline.source_id"
                           multiple
                           collapse-tags
                           placeholder="請選擇"
                           @change="changeSelect">
                    <el-option v-for="item in sourceList"
                               :key="item.value"
                               :label="item.name"
                               :disabled="item.disabled"
                               :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary"
                           @click="handleSearch">查詢</el-button>
            </el-form-item>
            <el-form-item>
                <el-button @click="clearSearch">清除</el-button>
            </el-form-item>
        </el-form>
        <div class="pd-top-bottom">
            <el-button size="medium"
                       type="primary"
                       @click="exportSummary">匯出</el-button>
        </div>
        <table class="descriptions-table"
               v-loading="listLoading">
            <tbody align="center">
                <tr>
                    <th></th>
                    <th>{{sourceName}}</th>
                </tr>
                <tr v-for="(item ,index) in renewDetail"
                    :key="index">
                    <td>{{item.description}}</td>
                    <td>{{item.amount}}</td>
                </tr>
                <tr v-if="Object.keys(renewDetail).length <1">
                    <td colspan="2">暫無資料</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { renewExpireList, domainSourceList, renewExpireListExport } from '../../../../api/index'
export default {
    data() {
        return {
            formInline: { source_id: [1, 3, 4, 5, 6] },
            sourceList: {},
            sourceName: '',
            renewDetail: {},
            listLoading: false,
            thisMonth: '',
        }
    },
    created() {
        this.getDateScope()
    },
    mounted() {
        this.getData()
        this.getDomainSourceList()
    },
    methods: {
        getData() {
            this.listLoading = true
            let data = {
                merchant_id: this.formInline.merchantId ? this.formInline.merchantId : '',
                source_id: JSON.stringify(this.formInline.source_id),
            }
            renewExpireList(data).then((res) => {
                if (res.code == 300) {
                    this.renewDetail = res.data.renew_detail
                    this.sourceName = res.data.source_name
                } else if (res.code == 500) {
                    this.renewDetail = {}
                    this.$message.error(res.message)
                }
                this.listLoading = false
            })
        },
        getDomainSourceList() {
            domainSourceList().then((res) => {
                this.sourceList = res.data.data_list
                this.changeDisabled(true, false)
            })
        },
        handleSearch() {
            if (!this.formInline.source_id) {
                this.$message.error('網域來源不能為空')
                return
            }
            this.getData()
        },
        clearSearch() {
            this.formInline = {}
            this.changeDisabled(false, false)
        },
        changeSelect(val) {
            if (val[0] === 2) {
                this.changeDisabled(false, true)
                return
            }
            if (val.length > 0) {
                this.changeDisabled(true, false)
            } else {
                this.changeDisabled(false, false)
            }
        },
        changeDisabled(type, type2) {
            this.sourceList.forEach((element, index) => {
                if (element.value === 2) {
                    this.sourceList[index].disabled = type
                } else {
                    this.sourceList[index].disabled = type2
                }
            })
        },
        exportSummary() {
            if (!this.formInline.source_id) {
                this.$message.error('網域來源不能為空')
                return
            }
            let data = {
                merchant_id: this.formInline.merchantId ? this.formInline.merchantId : '',
                source_id: JSON.stringify(this.formInline.source_id),
            }
            renewExpireListExport(data).then((res) => {
                this.excelTake(res, '匯出當月續約到期列表')
            })
        },
        //接收excel
        excelTake(res, title) {
            const xlsx = 'application/vnd.ms-excel'
            const blob = new Blob([res], { type: xlsx })
            const fileReader = new FileReader()
            let _this = this
            fileReader.readAsText(blob, 'utf-8')
            fileReader.onload = function () {
                if (res.type === 'application/json') {
                    let msg = JSON.parse(fileReader.result).message
                    if (JSON.parse(fileReader.result).code == 500) {
                        _this.$message.error(msg)
                    }
                } else {
                    const a = document.createElement('a') // 轉換完成，建立一個a標籤用於下載
                    a.download = `${title}.xlsx`
                    a.href = window.URL.createObjectURL(blob)
                    a.click()
                    a.remove()
                }
            }
        },
        //當月份
        getDateScope() {
            let date = new Date()
            let y = date.getFullYear()
            let m = date.getMonth() + 1 //月
            m = m < 10 ? '0' + m : m
            this.thisMonth = y + '-' + m
        },
    },
}
</script>

<style lang="scss" scoped>
.descriptions-table {
    width: 100%;
    border-collapse: collapse;
    th {
        border: 1px solid #ebeef5;
        background: #f5f7fa;
        padding: 8px 11px;
    }
    td {
        border: 1px solid #ebeef5;
        padding: 8px 11px;
        max-width: 200px;
        word-break: break-all;
    }
}
.pd-top-bottom {
    padding: 20px 0px;
}
</style>

